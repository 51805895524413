import React from 'react'
import Layout from "../components/App/Layout"
import NavbarFour from "../components/App/NavbarFour"
import Footer2 from "../components/App/Footer2"
import FourOFour from "../components/App/FourOFour"

const PageNotFound = () => {
    return (
        <Layout>
            <NavbarFour />
            <FourOFour />
            <Footer2 />
        </Layout>
    );
}

export default PageNotFound;