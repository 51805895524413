import React from 'react'
import { Link } from 'gatsby'
// import logo from "../../assets/images/obacoderslogo2.png"
// import footerMap from "../../assets/images/footer-map.png"

const FourOFour = () => {
    return (
        <div className="container">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1 align="center">404</h1>
            <p align="center">Sorry, looks like you made a wrong turn.</p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    );
}

export default FourOFour;